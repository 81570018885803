import {Slider} from './slider'

let EMAIL_REGEX = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
let PHONE_REGEX = /^((\+\d{1,4}|\d{1,4})[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{3,20}$/
// Слайдер
var mainSlider: Slider = new Slider(document.querySelector('.slider'), {
  radioFlag: true,
  arrowsFlag: false,
  autoSlideFlag: true
})


// Скрипт плавной прокрутки

var linkNav = document.querySelectorAll('[href^="#"]'), // выбираем все ссылки к якорю на странице
  V = 0.5 // скорость, может иметь дробное значение через точку (чем меньше значение - тем больше скорость)
for (var i = 0; i < linkNav.length; i++) {
  linkNav[i].addEventListener('click', function(e) { // по клику на ссылку
      e.preventDefault(); // отменяем стандартное поведение
      var w = window.pageYOffset, // производим прокрутку
          hash = this.href.replace(/[^#]*(.*)/, '$1') // к id элемента, к которому нужно перейти
      var t = document.querySelector(hash).getBoundingClientRect().top, // отступ от окна браузера до id
          start = null
      console.log(t)
      if (t > 1300) {
          V = 0.5
      }
      if (t > 2000) {
          V = 0.2
      }

      requestAnimationFrame(step); // функция анимации
      function step(time) {
          if (start === null) start = time
          var progress = time - start,
              r = (t < 0 ? Math.max(w - progress / V, w + t) : Math.min(w + progress / V, w + t));
          window.scrollTo(0, r)
          if (r != w + t) {
              requestAnimationFrame(step)
          } else {
              location.hash = hash // URL с хэшем
          }
      }
  }, false);
}


// Отправка формы

let data = new FormData()

function sendMail() {
  let company = document.querySelector('input[name="company"]').value
  let phone = document.querySelector('input[name="phone"]').value
  let comment = document.querySelector('textarea[name="comment"]').value
  let errorWrapper = document.querySelector('.error')
  if((company !== '' && company !== ' ') && (phone !== '' && phone !== ' ')) {
    console.log(company, phone)
    data.append('name', company)
    data.append('comment', comment)
    data.append('sibBio', 1)

    if(isValid(phone, EMAIL_REGEX)) {
        data.append('contact', phone)
        errorWrapper.innerText = ''
    } else if (isValid(phone, PHONE_REGEX)) {
        data.append('contact', phone)
        errorWrapper.innerText = ''
    } else {
        errorWrapper.innerText = 'Проверьте контактные данные'
        console.log('Проверьте контактные данные')
    }
  } else {
    errorWrapper.innerText = 'Поля "Ваше имя/Название компании" и "Номер телефона/e-mail адрес" не могут быть пустыми'
      console.log('Поля "Ваше имя/Название компании" и "Номер телефона/e-mail адрес" не могут быть пустыми')
  }

  request.open('POST', 'http://www.thsib.ru/news/contact_form/', true)
  request.onreadystatechange = function() {
      if (request.readyState === 4 && request.status === 200) {
          var json = JSON.parse(request.responseText)
          console.log(json)
          errorWrapper.innerText = 'Сообщение отправлено'
      }
  }
  if(data.has('name') && data.has('contact')) {
    request.send(data)
  } else {
      console.log('не отправлено')
  }
  
}

let btnSend = document.querySelector('.btn-submit')
if (btnSend) {
  btnSend.addEventListener('click', sendMail, false)
}

// Ajax запрос

let request: XMLHttpRequest = new XMLHttpRequest()

function isValid(str, pat) {
    let pattern = new RegExp(pat);
    return pattern.test(str);
}