import * as Hammer from 'hammerjs'

interface SlideOptions {
    radioFlag: boolean
    arrowsFlag: boolean
    autoSlideFlag: boolean
}


export class Slider {
    protected slides: Array<HTMLElement>
    protected radioButtons: Array<HTMLElement>
    protected leftArrow: HTMLElement
    protected rightArrow: HTMLElement
    protected contentSlider: HTMLElement
    protected timerId: number = -1

    protected slideIndex: number = 1

    constructor(protected container: Element, public readonly options?: SlideOptions) {
        this.contentSlider = <HTMLElement>this.container.querySelector('.contentSlider')

        this.initSlides();
        if(this.options.arrowsFlag) {
            this.initArrows()
            //console.log('arrows')
        }
        if(this.options.radioFlag) {
            this.initRadioButtons()
        }
        this.swipe()
        this.autoSlide()
    }

    protected initSlides(): void {
        this.slides = this.getChildrenByClassName(this.contentSlider, 'slide')
    }

    protected initArrows(): void {
        this.leftArrow = <HTMLElement>this.contentSlider.querySelector('.arrow.left')
        this.leftArrow.addEventListener('click', () => this.slide(this.slideIndex - 1), false)

        this.rightArrow = <HTMLElement>this.contentSlider.querySelector('.arrow.right')
        this.rightArrow.addEventListener('click', () => this.slide(this.slideIndex + 1), false)


    }

    protected initRadioButtons(): void {
        const radioButtonsWr: HTMLElement = this.getChildrenByClassName(this.contentSlider, 'radioButtons')[0]
        //console.log(radioButtonsWr, radioButtonsWr.querySelectorAll('.radio'))
        //console.log([...<NodeListOf<HTMLElement>>radioButtonsWr.querySelectorAll('.radio')])

        this.radioButtons = this.getChildrenByClassName(radioButtonsWr, 'radio')//[...<NodeListOf<HTMLElement>>radioButtonsWr.querySelectorAll('.radio')]
        this.radioButtons.forEach((el, index) => {
            el.addEventListener('click', () => { this.slide(index + 1); console.log('rb click', index, el); }, false)
        })
    }

    protected slide(index: number): void {
        this.slideIndex = index

        if(this.slideIndex > this.slides.length) {
            this.slideIndex = 1
        } else if( this.slideIndex < 1) {
            this.slideIndex = this.slides.length
        }

        this.slides.forEach((el, index) => {
            el.classList.add('disactive')
            if(this.options.radioFlag) {
                this.radioButtons[index].classList.remove('active')
            }
        })

        this.slides[this.slideIndex - 1].classList.remove('disactive')
        if(this.options.radioFlag) {
            this.radioButtons[this.slideIndex - 1].classList.add('active')
        }
    }

    protected swipe(): void {
        const self = this
        this.slides.forEach((el, index) => {
            let mc = new Hammer(el)
            mc.on('swiperight', function(ev) {
                self.slide(self.slideIndex + 1)
            })


            mc.on('swipeleft', function(ev) {
                self.slide(self.slideIndex - 1)
            })
        })
    }

    protected getChildrenByClassName(parent: HTMLElement, className: string): Array<HTMLElement> {
        const res: Array<HTMLElement> = []

        for (let i = 0; i < parent.children.length; i++) {
            if (parent.children[i].classList.contains(className)) {
                res.push(<HTMLElement>parent.children[i])
            }
        }

        return res
    }

    public autoSlide(): void {        
        let self  = this
        if(this.options.autoSlideFlag) {
            this.timerId = setInterval(() => {
                self.slide(self.slideIndex + 1)
            }, 3000)
        } else {
            if(this.timerId >= 0) {
                clearInterval(this.timerId)
            }
        }
    }
}
